import appContext, { dataBindingAppContext } from './DataBindingAppContext'
import { get, pick, flatten, difference, isEqual } from 'lodash-es'
import DataProvider from '../data/DataProvider'
import {
  convertFromCustomFormat,
  convertToCustomFormat,
} from '@wix/cloud-elementory-protocol'
import { PRIMARY } from '../../lib/data/sequenceType'
import completeControllerConfigs from '../dataset-controller/completeControllerConfigs'
import { parseUrlPattern } from '../helpers/urlUtils'
import { Deferred } from '../helpers'
import { createRecordStoreService } from '../record-store'
import createControllerFactory from '../dataset-controller/controllerFactory'
import { createDependencyManager } from '../../lib/dataset-controller/dependencyManager'
import AppState from './AppState'
import {
  DataBindingLogger,
  Trace,
  Breadcrumb,
  createErrorReporting,
  createBreadcrumbReporting,
  createVerboseReporting,
} from '../logger'
export default class DataBinding {
  #dataProvider
  #warmupCache
  #staticCache
  #features
  #listenersByEvent
  #logger

  //TODO: invert
  #recordStoreCache

  constructor({
    platform,
    dataFetcher,
    warmupCache,
    staticCache,
    features,
    listenersByEvent,
    logger,
    i18n,
    global,
    //TODO: add to warmupCache, and distinguish there which data should be saved to warmup store.
    // for now routerData schemas are also saved to the warmup data which is not oprimal
  }) {
    const dataBindingLogger = new DataBindingLogger(logger, global)

    dataBindingAppContext.set({
      platform,
      features,
      dataFetcher,
      i18n,
      appState: new AppState(),
      logger: dataBindingLogger,
      errorReporting: createErrorReporting(dataBindingLogger),
      breadcrumbReporting: createBreadcrumbReporting(dataBindingLogger),
      verboseReporting: createVerboseReporting(dataBindingLogger),
    })

    this.#dataProvider = new DataProvider()
    this.#warmupCache = warmupCache
    this.#staticCache = staticCache
    this.#features = features
    this.#logger = dataBindingLogger

    this.#recordStoreCache = {}
    this.#listenersByEvent = listenersByEvent
  }

  initializeDatasets({
    //TODO: temp interface
    datasetConfigs,
    firePlatformEvent,
  }) {
    try {
      return this.#logger.log(
        new Trace('databinding/createControllers', () =>
          this.#initializeDatasets({ datasetConfigs, firePlatformEvent }),
        ),
      )
    } catch (e) {
      this.#logger.logError(e, 'Datasets initialisation failed')
      return []
    }
  }

  #initializeDatasets({
    //TODO: temp interface
    datasetConfigs: _datasetConfigs,
    firePlatformEvent,
  }) {
    const {
      platform: {
        settings: {
          mode: { name: modeName, csr, ssr },
          env: { livePreview },
        },
      },
    } = appContext

    const datasetConfigs = completeControllerConfigs(_datasetConfigs)
    const updatedDatasetIds = this.#updateDatasetConfigsState(datasetConfigs)
    const warmupDataIsEnabled = this.#features.warmupData

    const fetchingAllDatasetsData = []
    const renderingControllers = []
    const {
      resolve: renderDeferredControllers,
      promise: renderingRegularControllers,
    } = new Deferred()

    this.#dataProvider.setSchemas({
      ...((warmupDataIsEnabled && csr && this.#warmupCache.getSchemas()) || {}),
      ...(this.#staticCache.getSchemas() || {}),
    })

    const schemasLoading = this.#logger.log(
      new Trace('databinding/loadSchemas', () =>
        this.#dataProvider
          .loadSchemas(getUniqueCollectionIds(datasetConfigs))
          .then(
            () =>
              warmupDataIsEnabled &&
              ssr &&
              this.#warmupCache.setSchemas(this.#dataProvider.getSchemas()),
          ),
      ),
    )

    // TODO: Remove after refactoring datasetConfigs
    this.#dataProvider.setDatasetConfigs(
      datasetConfigs.map(
        ({
          config: {
            dataset: { collectionName: collectionId },
          },
          compId: datasetId,
          type,
        }) => ({
          collectionId,
          datasetId,
          type,
        }),
      ),
    )

    const warmupStore =
      csr && warmupDataIsEnabled && this.#warmupCache.getDataStore()
    if (warmupStore) {
      this.#dataProvider.setStore(convertFromCache(warmupStore))
    }

    const staticStore = this.#staticCache.getDataStore()
    if (staticStore) {
      this.#dataProvider.setStaticStore(convertFromCache(staticStore))
    }

    this.#dataProvider.createInitialDataRequest(
      this.#getInitialDataRequestConfigs(datasetConfigs, updatedDatasetIds),
    )

    const connectionsGraph = datasetConfigs.reduce(
      (g, { compId, connections }) => {
        g[compId] = connections.map(({ compId }) => compId)
        return g
      },
      {},
    )

    const dependencyManager = createDependencyManager({ datasetConfigs })

    const controllers = datasetConfigs.map(
      ({
        type,
        config,
        connections,
        compId: datasetId,
        livePreviewOptions: {
          shouldFetchData: dataIsInvalidated,
          compsIdsToReset: updatedCompIds = [],
        } = {},
        dynamicPageData,
      }) => {
        const { datasetIsRouter, datasetIsDeferred } =
          config.datasetStaticConfig
        this.#logger.log(
          new Breadcrumb({
            category: 'createControllers',
            message: 'warmup data contents',
            data: {
              datasetId,
              datasetType: type,
              mode: modeName,
              warmupData: Boolean(warmupStore),
            },
          }),
        )

        const recordStoreService = createRecordStoreService({
          primaryDatasetId: datasetId,
          recordStoreCache: this.#recordStoreCache,
          refreshStoreCache: dataIsInvalidated,
          dataProvider: this.#dataProvider,
          controllerConfig: config,
        })

        const {
          promise: fetchingDatasetData,
          resolve: markDatasetDataFetched,
        } = new Deferred()
        if (!datasetIsRouter && !datasetIsDeferred) {
          // But router will be in dataStore anyway. Filter out?
          fetchingAllDatasetsData.push(fetchingDatasetData)
        }

        const {
          promise: renderingController,
          resolve: markControllerAsRendered,
        } = new Deferred()
        renderingControllers.push(renderingController)

        const controllerFactory = createControllerFactory(this.#logger, {
          dependencyManager,
          controllerConfig: config,
          datasetType: type,
          connections,
          connectionsGraph,
          recordStoreService,
          dataProvider: this.#dataProvider,
          firePlatformEvent: firePlatformEvent(datasetId),
          dynamicPagesData:
            datasetIsRouter && dynamicPageData
              ? extractRouterPayload({
                  dynamicPageData,
                  items: this.#staticCache.getItems(),
                  config,
                  convertFromCustomFormat,
                })
              : undefined,
          datasetId,
          schemasLoading,
          listenersByEvent: this.#listenersByEvent,
          updatedCompIds,
          markControllerAsRendered,
          markDatasetDataFetched,
          renderingRegularControllers,
          modeIsLivePreview: livePreview,
          modeIsSSR: ssr,
          useLowerCaseDynamicPageUrl: dynamicPageData?.lowercase,
        })

        const dataset = controllerFactory.createRealDataset()
        dependencyManager.registerDataset({
          id: datasetId,
          api: dataset.api,
          config,
        })

        return extractPlatformControllerAPI(dataset)
      },
    )

    if (ssr && warmupDataIsEnabled && fetchingAllDatasetsData.length) {
      Promise.all(fetchingAllDatasetsData).then(() => {
        this.#warmupCache.setDataStore(
          convertToCache(this.#dataProvider.getStore()),
        )
      })
    }
    Promise.all(renderingControllers).then(renderDeferredControllers)

    return controllers
  }

  #updateDatasetConfigsState(datasetConfigs) {
    const { appState } = appContext
    return datasetConfigs.reduce(
      (updatedDatasetIds, { compId: datasetId, config: { dataset } }) => {
        const datasetConfigState = appState.datasetConfigs.get(datasetId)
        if (datasetConfigState && !isEqual(datasetConfigState, dataset)) {
          updatedDatasetIds.push(datasetId)
        }
        appState.datasetConfigs.set(datasetId, dataset)

        return updatedDatasetIds
      },
      [],
    )
  }

  #getInitialDataRequestConfigs(datasetConfigs, updatedDatasetIds) {
    return datasetConfigs.reduce(
      (
        acc,
        {
          compId: datasetId,
          config: {
            datasetStaticConfig: { sequenceType },
          },
          livePreviewOptions: { shouldFetchData } = {},
        },
      ) =>
        sequenceType === PRIMARY
          ? [
              ...acc,
              {
                id: datasetId,
                refresh:
                  shouldFetchData || updatedDatasetIds.includes(datasetId),
              },
            ]
          : acc,
      [],
    )
  }
}

const getUniqueCollectionIds = datasetConfigs => {
  const uniqueCollectionIds = datasetConfigs.reduce(
    (
      uniqueIds,
      {
        config: {
          dataset: { collectionName },
        },
      },
    ) => (collectionName ? uniqueIds.add(collectionName) : uniqueIds),
    new Set(),
  )

  return [...uniqueCollectionIds]
}

const extractRouterPayload = ({
  dynamicPageData,
  config,
  items,
  convertFromCustomFormat,
}) => {
  const { dynamicUrl, userDefinedFilter } = dynamicPageData
  const record = convertFromCustomFormat(items)[0]

  const datasetSort = get(config, 'dataset.sort', []) || []
  const patternFields =
    dynamicUrl && record ? parseUrlPattern(dynamicUrl).fields : []
  const datasetSortFields = getDatasetSortFields(datasetSort)
  const unsortedPatternFields = difference(patternFields, datasetSortFields)
  const sort = getSortObject([
    ...datasetSort,
    ...getDefaultFieldsSort(unsortedPatternFields),
  ])
  const sortFields = [...datasetSortFields, ...unsortedPatternFields]

  const dynamicUrlPatternFieldsValues =
    extractDynamicUrlPatternFieldsValuesFromRecord(
      record,
      sortFields,
      patternFields,
    )

  return {
    dynamicUrl,
    userDefinedFilter,
    dynamicUrlPatternFieldsValues,
    sort,
    sortFields,
    patternFields,
  }
}

const getDatasetSortFields = sort =>
  flatten(sort.map(sortItem => Object.keys(sortItem).map(key => key)))

const getSortObject = sortArray =>
  sortArray.reduce(
    (accumulator, currentValue) => Object.assign(accumulator, currentValue),
    {},
  )

const getDefaultFieldsSort = patternFields =>
  patternFields.map(field => ({ [field]: 'asc' }))

const extractDynamicUrlPatternFieldsValuesFromRecord = (
  record,
  sortFields,
  patternFields,
) => {
  const sortAndPatternFields = patternFields.concat(sortFields)
  return patternFields.length ? pick(record, sortAndPatternFields) : null
}

const extractPlatformControllerAPI = ({ pageReady, exports, dispose }) => ({
  pageReady,
  exports,
  dispose,
})

const createConverter = convert => dataStore => {
  // TODO: change date format to ISO string and this conversion won't be needed
  if (dataStore) {
    return {
      ...dataStore,
      recordsByCollectionId: Object.entries(
        dataStore.recordsByCollectionId,
      ).reduce((acc, [collection, recordsById]) => {
        acc[collection] = convert(recordsById)
        return acc
      }, {}),
    }
  }
}
const convertToCache = createConverter(convertToCustomFormat)
const convertFromCache = createConverter(convertFromCustomFormat)
