import { flow } from 'lodash-es'
import {
  DATEPICKER_ROLE,
  RADIOGROUP_ROLE,
  TIMEPICKER_ROLE,
  UPLOAD_BUTTON_ROLE,
} from '@wix/wix-data-client-common/src/connection-config/roles'
import convertDateTime from './convertDateTime'
import convertUploadResult from './convertUploadResult'
import { FieldType } from '@wix/wix-data-schema-types'
import { toDateOnlyISOString } from './dateTimeUtils'

const { reference, dateTime, date } = FieldType

export default ({
  value,
  currentValue,
  fieldType,
  fieldName,
  role,
  utils: { referenceFetcher } = {},
}) => {
  const arrayOfConvertorsAndConditions = [
    {
      converter: value =>
        referenceFetcher(
          value, // referenced record id
          fieldName,
        ),
      condition: fieldType === reference,
    },
    {
      converter: value => convertDateTime(currentValue, value),
      condition:
        fieldType === dateTime &&
        [DATEPICKER_ROLE, TIMEPICKER_ROLE, RADIOGROUP_ROLE].includes(role),
    },
    {
      converter: toDateOnlyISOString,
      condition: fieldType === date && role === DATEPICKER_ROLE,
    },
    {
      converter: value =>
        convertUploadResult({
          value,
          currentValue,
          fieldType,
        }),
      condition: role === UPLOAD_BUTTON_ROLE,
    },
  ]

  const conversionFlow = arrayOfConvertorsAndConditions.reduce(
    (acc, { converter, condition }) => {
      if (condition) acc.push(converter)
      return acc
    },
    [],
  )
  return flow(conversionFlow)(value)
}
